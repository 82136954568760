import './clinical-studies.scss'
function ClinicalStudies() {


    return(
        <div className="studies">
            <div className="first">    
            <div className="text">
                <p>Clinical studies conducted in USA, Germany, Italy, the Netherlands, UK, Israel ,China and Japan showed impressive results in treating both children and adults suffering from all kinds of nasal congestion, including viral congestion, chronic congestion, sinusitis and allergies.</p>
            </div>           
            </div>

            

            <div className="two">
                <div className="left">
                    <h3 className="two-title" onClick={() => {
                        window.open("https://pubmed.ncbi.nlm.nih.gov/28780227/")
                    }} >Insights into the immune manipulation mechanisms of pollen allergens by protein domain profiling</h3>
                    <div className="link-top"></div>
                    <div className="content">
                        <h5>Seema Patel, Aruna Rani, Arun Goyal</h5>
                    </div>
                </div>
                <div className="right">
                    <div className="text">
                        <h4>Abstract</h4>
                        <p>Plant pollens are airborne allergens, as their inhalation causes immune activation, leading to rhinitis, conjunctivitis, sinusitis and oral allergy syndrome. A myriad of pollen proteins belonging to profilin, expansin, polygalacturonase, glucan endoglucosidase, pectin esterase, and lipid transfer protein class have been identified. In the present in silico study, the protein domains of fifteen pollen sequences were extracted from the UniProt database and submitted to the interactive web tool SMART (Simple Modular Architecture Research Tool), for finding the protein domain profiles. Analysis of the data based on custom-made scripts revealed the conservation of pathogenic domains such as OmpH, PROF, PreSET, Bet_v_1, Cpl-7 and GAS2. Further, the retention of critical domains like CHASE2, Galanin, Dak2, DALR_1, HAMP, PWI, EFh, Excalibur, CT, PbH1, HELICc, and Kelch in pollen proteins, much like cockroach allergens and lethal viruses (such as HIV, HCV, Ebola, Dengue and Zika) was observed. Based on the shared motifs in proteins of taxonomicall-ydispersed organisms, it can be hypothesized that allergens and pathogens manipulate the human immune system in a similar manner. Allergens, being inanimate, cannot replicate in human body, and are neutralized by immune system. But, when the allergens are unremitting, the immune system becomes persistently hyper-sensitized, creating an inflammatory milieu. This study is expected to contribute to the understanding of pollen allergenicity and pathogenicity.</p>
                    </div>
                </div>
            </div>

            <div className="three">
                <div className="left">
                    <h3 className="three-title"  onClick={() => {
                        window.open("https://link.springer.com/article/10.1007/s12650-014-0238-x")
                    }}>Visualization of particle deposition in human nasal cavities</h3>
                    <div className="link-top"></div>
                    <div className="content">
                        <h5>H.Sakai, Y.Watanabe, T.Sera, H.Yokota & G.Tanaka</h5>
                    </div>
                    <span className="img"></span>
                </div>
                <div className="right">
                    <div className="text">
                        <h4>Abstract</h4>
                        <p>The location and concentration of particle deposition of pollen by filtration in the human nasal cavity were visualized in a transparent silicone nasal airway model using laser-induced fluorescence (LIF) to clarify the relationship between flow and particle deposition. The model was created from a water-soluble plaster mold fabricated by a 3D printer based on X-ray computed tomography images. The working fluid was air and the tracer particles as a substitute for cedar pollen were lycopodium powder doped with fluorescent dye (Rhodamine 6G). After particle deposition, the nasal airway model was filled with an aqueous solution of glycerin that had the same refractive index as silicone. Then, LIF was applied to illuminate the deposited particles with a YAG laser sheet. Results revealed that particle deposition in the right and left cavities was highly heterogeneous and was related to the complex flow structure in the nasal cavities.</p>
                    </div>
                </div>
            </div>

            <div className="four">   
                <div className="left">
                    <h3 className="four-title"  onClick={() => {
                        window.open("https://pubmed.ncbi.nlm.nih.gov/27890918/")
                    }}>2-Hydroxy-3-methoxybenzoic acid attenuates mast cell-mediated allergic reaction in mice via modulation of the FcεRI signaling pathway</h3>
                    <div className="link-top"></div>
                    <div className="content">
                        <h5>Yeon-Yong Kim, In-Gyu Je, Min Jong Kim, Byeong-Cheol Kang, Young-Ae Choi, Moon-Chang Baek, Byungheon Lee, Jin Kyeong Choi, Hae Ran Park, Tae-Yong Shin, Soyoung Lee, Seung-Bin Yoon, Sang-Rae Lee, Dongwoo Khang, Sang-Hyun Kim</h5>
                    </div>
                </div>
                <div className="right">
                    <div className="text">
                        <h4>Abstract</h4>
                        <p>Mast cells are important effector cells in immunoglobulin (Ig) E-mediated allergic reactions such as asthma, atopic dermatitis and rhinitis. Vanillic acid, a natural product, has shown anti-oxidant and anti-inflammatory activities. In the present study, we investigated the anti-allergic inflammatory effects of ortho-vanillic acid (2-hydroxy-3-methoxybenzoic acid, o-VA) that was a derivative of vanillic acid isolated from Amomum xanthioides. In mouse anaphylaxis models, oral administration of o-VA (2, 10, 50 mg/kg) dose-dependently attenuated ovalbumin-induced active systemic anaphylaxis and IgE-mediated cutaneous allergic reactions such as hypothermia, histamine release, IgE production and vasodilation; administration of o-VA also suppressed the mast cell degranulator compound 48/80-induced anaphylaxis. In cultured mast cell line RBL-2H3 and isolated rat peritoneal mast cells in vitro, pretreatment with o-VA (1-100 μmol/L) dose-dependently inhibited DNP-HSA-induced degranulation of mast cells by decreasing the intracellular free calcium level, and suppressed the expression of pro-inflammatory cytokines TNF-α and IL-4. Pretreatment of RBL-2H3 cells with o-VA suppressed DNP-HSA-induced phosphorylation of Lyn, Syk, Akt, and the nuclear translocation of nuclear factor-κB. In conclusion, o-VA suppresses the mast cell-mediated allergic inflammatory response by blocking the signaling pathways downstream of high affinity IgE receptor (FcεRI) on the surface of mast cells.</p>
                    </div>
                </div>
            </div>

            <div className="five">   
                <div className="left">
                    <h3 className="five-title"  onClick={() => {
                        window.open("https://pubmed.ncbi.nlm.nih.gov/32751734/")
                    }}>FcεRI Signaling in the Modulation of Allergic Response: Role of Mast Cell-Derived Exosomes</h3>
                    <div className="link-top"></div>
                    <div className="content">
                        <h5>Mario Lecce, Rosa Molfetta, Nadia Domenica Milito, Angela Santoni, Rossella Paolini</h5>
                    </div>
                </div>
                <div className="right">
                    <div className="text">
                        <h4>Abstract</h4>
                        <p>Mast cells (MCs) are immune cells that act as environment resident sentinels playing a crucial role in Th2-mediated immune responses, including allergic reactions. Distinguishing features of MCs are the presence of numerous cytoplasmic granules that encapsulate a wide array of preformed bio-active molecules and the constitutive expression of the high affinity receptor of IgE (FcεRI). Upon FcεRI engagement by means of IgE and multivalent antigens, aggregated receptors trigger biochemical pathways that ultimately lead to the release of granule-stored and newly synthesized pro-inflammatory mediators. Additionally, MCs are also able to release exosomes either constitutively or upon stimulation. Exosomes are nanosized vesicles of endocytic origin endowed with important immunoregulatory properties, and represent an additional way of intercellular communication. Interestingly, exosomes generated upon FcεRI engagement contain co-stimulatory and adhesion molecules, lipid mediators, and MC-specific proteases, as well as receptor subunits together with IgE and antigens. These findings support the notion that FcεRI signaling plays an important role in influencing the composition and functions of exosomes derived by MCs depending on their activation status.</p>
                    </div>
                </div>
            </div>

            <div className="six">
                <div className="left">
                    <h3 className="six-title"  onClick={() => {
                        window.open("https://journals.aai.org/jimmunol/article/170/4/1839/71114/Temperature-Effect-on-IgE-Binding-to-CD23-Versus")
                    }}>Temperature Effect on IgE Binding to CD23 Versus FcεRI</h3>
                    <div className="link-top"></div>
                    <div className="content">
                        <h5>Bing-Hung Chen, Michelle A. Kilmon, Check Ma, Timothy H. Caven, Yee Chan-Li, Anne E. Shelburne, Robert M. Tombes, Eric Roush, Daniel H. Conrad</h5>
                    </div>
                    <span className="img"></span>
                </div>
                <div className="right">
                    <div className="text">
                        <h4>Abstract</h4>
                        <p>A chimeric soluble CD23, consisting of the extracellular domain of mouse CD23 and a modified leucine zipper (lz-CD23), has been shown to inhibit IgE binding to the FcεRI. A similar human CD23 construct was also shown to inhibit binding of human IgE to human FcεRI. In both systems, the inhibition was found to be temperature dependent; a 10-fold molar excess of lz-CD23 gave 90-98% inhibition at 4°C, dropping to 20-30% inhibition at 37°C. Surface plasmon resonance analysis of lz-CD23 binding to an IgE-coated sensor chip suggested that the effective concentration of lz-CD23 was lower at the higher temperatures. Analysis of 125I-IgE binding to CD23+-Chinese hamster ovary cells also indicated that increased temperature resulted in a lower percentage of IgE capable of interacting with CD23. In contrast, IgE interacts more effectively with FcεRI+-rat basophilic leukemia cells at 37°C compared with 4°C. The results support the concept that the open and closed IgE structures found by crystallography interact differently with the two IgE receptors and suggest that temperature influences the relative percentage of IgE in the respective structural forms. Changes in CD23 oligomerization also plays a role in the decreased binding seen at physiological temperatures.</p>
                    </div>
                </div>
            </div>

            <div className="seven">
                <div className="left">
                    <h3 className="seven-title"  onClick={() => {
                        window.open("https://pubmed.ncbi.nlm.nih.gov/6750609/")
                    }}>Treatment of perennial allergic rhinitis by local hyperthermia</h3>
                    <div className="link-top"></div>
                    <div className="content">
                        <h5>A Yerushalmi, S Karman, A Lwoff</h5>
                    </div>
                </div>
                <div className="right">
                    <div className="text">
                        <h4>Abstract</h4>
                        <p>Ninety-five patients with documented perennial allergic rhinitis have been treated with local hyperthermia of the nasal passages in a randomized double-blind trial. The treatment consisted of one series of three 30-min insufflations of humidified air at 43 degrees C at a 2-hr interval. In the active treatment group, 75% (P less than 0.00003) and 68% (P less than 0.00001) of the patients were free of symptoms 1 week and 1 month, respectively, after treatment, compared with a 28% and 17% response in the placebo group. We conclude that local hyperthermia is effective in the treatment of perennial allergic rhinitis.</p>
                    </div>
                </div>
            </div>

            <div className="eight">
                <div className="left">
                    <h3 className="eight-title"  onClick={() => {
                        window.open("https://www.jstage.jst.go.jp/article/orltokyo1958/32/Supplement3/32_Supplement3_255/_article/-char/ja/")
                    }}>通年性鼻アルギ一及び感冒時の鼻炎に対する鼻局所温熱療法の臨床的検討</h3>
                    <div className="link-top"></div>
                    <div className="content">
                        <h6>1）奈良県立医科大学耳鼻咽喉科学教室 
                            2） 奈良県立奈良病院耳鼻咽喉科 
                            3）榛原町立病院耳鼻咽喉科 
                            4）奈良県立三室病院耳鼻咽喉科 
                            5）川本耳鼻咽喉科医院 
                            6）東辻耳鼻咽喉科医院 
                            7）福田耳鼻咽喉科医院 
                            8）山本耳鼻咽喉科医院 
                            9）東海大学医学部耳鼻咽喉科学教室 
                            10）伊勢原協同病院耳鼻咽喉科 
                            11）東海大学大磯病院耳鼻咽喉科 
                            12）田無病院耳鼻咽喉科 
                            13）佼成病院耳鼻咽喉科 
                            14）旭川医科大学医学部耳鼻咽喉科学教室 
                            15）富山耳鼻咽喉科医院</h6>
                    </div>
                </div>
                <div className="right">
                    <div className="text">
                        <h4>はじめに</h4>
                        <p>今日，鼻副鼻腔，下気道疾患に対するエアロソル撩法は日常臨床に広く用いられている。鼻局所温熱療法装置はフランス·パスツ一ル研究所とイスラエル・ワイスマン研究所の協同研究にて開発されたもので，蒸留水を43Cに加温し，エアロソル粒子として発生する装置で，ウイルス感染に伴う急性鼻炎や鼻アレルギ一に対する高い有用性がヨ一ロッバを中心に報告されている”。最近,この鼻局所温熱療法装置(リノセルム®)が我国にも導入され，基礎的·臨床的研究が行われ，本療法の有用性や安全性が確かめられている。さらに大山らは，通年性鼻アレルギ一及び感冒時の鼻炎の患者で局所温熱療法の二重盲検比較試験による臨床的検討を行い，その有効性，安全性及び有用性について，良好な結果を報告している”。今回，旭川，神奈川及び奈良の3地区にて，通年性鼻アレルギ一及び感冒時の鼻炎に対して鼻局所温熱療法を行い，その有効性，安全性及び有用性。</p>
                    </div>
                </div>
            </div>

            <div className="nine">
                <div className="left">
                    <h3 className="nine-title"  onClick={() => {
                        window.open("https://pubmed.ncbi.nlm.nih.gov/7956408/")
                    }}>Nasal hyperthermia and simple irrigation for perennial rhinitis. Changes in inflammatory mediators</h3>
                    <div className="link-top"></div>
                    <div className="content">
                        <h5>J W Georgitis</h5>
                    </div>
                </div>
                <div className="right">
                    <div className="text">
                        <h4>Abstract</h4>
                        <p><span className="point">Study objective: </span>Local nasal hyperthermia or inhalation of heated water vapor is often recommended as a home remedy for various rhinitis disorders such as the common cold and allergic rhinitis. Inhaled heated vapor treatments and simple saline solution nasal irrigation were investigated for their effect on inflammatory mediator production in nasal secretions.</p>
                        <p><span className="point">Design: </span>Three treatments were given for nasal irrigation: heated water particles (large particle water vapor) at 43 degrees C, heated molecular water vapor (molecular water vapor) at 41 degrees C, and simple saline solution nasal irrigation. Nasal washes were done before each treatment (baseline), immediately after treatments, and at 30 min, 2, 4, and 6 h. Histamine, prostaglandin D2, and leukotriene C4 (LTC4) concentrations were measured in nasal secretions and compared with baseline values.</p>
                        <p><span className="point">Patients and participants: </span>Thirty symptomatic patients with active perennial allergic rhinitis underwent three treatments at weekly intervals.</p>
                        <p><span className="point">Measurements and results: </span>Nasal histamine concentrations fell substantially with the nasal irrigation (p＜0.01 immediately posttreatment and at 30 min; p＜0.05 at 2, 4, and 6 h). Large particle vapor also reduced histamine concentrations for up to 4 h posttreatment compared with baseline values (p＜0.05). Alternatively, molecular water vapor did not alter nasal histamine concentrations. Surprisingly, the three treatments did not alter prostaglandin D2 concentrations over the 6 h. Leukotriene C4 concentrations fell briefly after the large particle treatment but did not with the molecular water vapor. With saline solution irrigation, LTC4 concentrations in nasal secretions were lower than baseline at 30 min to 4 h after a treatment (p＜0.05).</p>
                        <p><span className="point">Conclusions: </span>This study demonstrated the usefulness of large particle vapor treatment and saline solution irrigation in reducing inflammatory mediators in nasal secretions and indirectly supports the clinical efficacy of these treatments for chronic rhinitis.</p>
                    </div>
                </div>
            </div>

            <div className="ten">
                <div className="left">
                    <h3 className="ten-title"  onClick={() => {
                        window.open("https://pubmed.ncbi.nlm.nih.gov/3303983/")
                    }}>Effects of steam inhalation on nasal patency and nasal symptoms in patients with the common cold</h3>
                    <div className="link-top"></div>
                    <div className="content">
                        <h5>D Ophir, Y Elad</h5>
                    </div>
                </div>
                <div className="right">
                    <div className="text">
                        <h4>Abstract</h4>
                        <p>The effects of steam inhalation on nasal patency and on nasal symptoms were studied in 62 patients with the common cold by a double-blind, randomized, placebo-controlled clinical trial. Treatment consisted of two 20-minute sessions, during which the patient inhaled saturated, hot (42 degrees to 44 degrees C) air through the nose. The subjective response was recorded by each patient during the week following treatment on a daily symptom score card. Nasal patency was determined before treatment, the following day, and 1 week later by measuring peak nasal expiratory and inspiratory air flow. Highly reproducible results were obtained by using these objective methods. Steam inhalation resulted in alleviation of cold symptoms and increased nasal patency in a significantly higher percentage of patients in the actively treated group than in the placebo-treated group. Possible explanations for the effectiveness of treatment are discussed.</p>
                    </div>
                </div>
            </div>

            <div className="eleven">
                <div className="left">
                    <h3 className="eleven-title"  onClick={() => {
                        window.open("https://pubmed.ncbi.nlm.nih.gov/32534983/")
                    }}>Efficacy of nasal irrigation with hypertonic saline on chronic rhinosinusitis: systematic review and meta-analysis</h3>
                    <div className="link-top"></div>
                    <div className="content">
                        <h5>Lei Liu, Min Pan, Yimin Li, Guojing Tan, Yucheng Yang</h5>
                    </div>
                </div>
                <div className="right">
                    <div className="text">
                        <h4>Abstract<span className="end">in English, Portuguese</span></h4>
                        <p><span className="point">Introduction: </span>Currently, several different concentrations of saline are recommended for use in nasal irrigation. Increasing studies show that nasal irrigation with hypertonic saline is more effective than traditional saline in the treatment of rhinosinusitis, but there have been few systematic analyses of the effect of nasal irrigation with hypertonic saline on chronic rhinosinusitis.</p>
                        <p><span className="point">Objective: </span>We sought to compare the effects of hypertonic saline and isotonic saline in the treatment of rhinosinusitis in order to provide a reference for clinical nasal irrigation for chronic rhinosinusitis treatment.</p>
                        <p><span className="point">Methods: </span>Medline, cochrane library, EMBASE, PubMed, Chinese biomedical journal database, China national knowledge infrastructure, Wanfang database, and other databases were searched, and the searching was supplemented by manual searches for relevant references to treatment of rhinosinusitis by saline nasal irrigation. The last retrieval date was March 2018. The included studies were evaluated for quality, and data were extracted for meta-analysis using RevMan 5.3.</p>
                        <p><span className="point">Results: </span>Seven studies were included. Effects favoring hypertonic saline on nasal symptoms were greater in 4 subgroups. These were (1) patients with nasal secretion (SMD=1.52; 95% CI: 1.04, 2.00; p＜0.01), (2) patients with congestion (SMD=1.52; 95% CI: 1.04, 2.00; p＜0.01), (3) patients with headache (SMD=0.82; 95% CI: 0.38, 1.26; p＜0.01), (4) patients with overall symptomatic relief (SMD=1.63; 95% CI: 0.83, 2.44; p＜0.01). However, no difference was shown in smell improvement (SMD=0.47; 95% CI: -0.65, 1.59; p=0.41) and radiologic scores improvement (SMD=2.44; 95% CI: -3.14, 8.02; p＜0.01). Besides, hypertonic saline showed greater improvement in mucociliary clearance time scores than did the isotonic saline group (SMD=1.19; 95% CI: 0.78, 1.60; p＜0.01). Hypertonic saline brought greater minor adverse effects.</p>
                        <p><span className="point">Conclusion: </span>Compared with isotonic saline, hypertonic saline nasal irrigation for the treatment of chronic rhinosinusitis is significantly more effective and has mild side effects in improving nasal symptoms and ciliary movement, but there is no significant difference in imaging findings and smell improvement. Although hypertonic saline is worthy of widespread use in clinical practice, it is still necessary to further study the exact manner and concentration of nasal irrigation.</p>
                    </div>
                </div>
            </div>

            <div className="twelve">
                <div className="left">
                    <h3 className="twelve-title"  onClick={() => {
                        window.open("https://pubmed.ncbi.nlm.nih.gov/29253007/")
                    }}>Hypertonic saline attenuates the cytokine-induced pro-inflammatory signature in primary human lung epithelia</h3>
                    <div className="link-top"></div>
                    <div className="content">
                        <h5>Sanchayita Mitra, Daran Schiller, Cameron Anderson, Fabia Gamboni, Angelo D'Alessandro, Margeurite Kelher, Christopher C Silliman, Anirban Banerjee, Kenneth L Jones</h5>
                    </div>
                </div>
                <div className="right">
                    <div className="text">
                        <h4>Abstract</h4>
                        <p>Trauma/hemorrhagic shock is a complex physiological phenomenon that leads to dysregulation of many molecular pathways. For over a decade, hypertonic saline (HTS) has been used as an alternative resuscitation fluid in the setting of trauma/hemorrhagic shock. In addition to restoring circulating volume within the vascular space, studies have shown a positive immunomodulatory effect of HTS. Targeted studies have shown that HTS affects the transcription of several pro-inflammatory cytokines by inhibiting the NF-κB-IκB pathway in model cell lines and rats. However, few studies have been undertaken to assess the unbiased effects of HTS on the whole transcriptome. This study was designed to interrogate the global transcriptional responses induced by HTS and provides insight into the underlying molecular mechanisms and pathways affected by HTS. In this study, RNA sequencing was employed to explore early changes in transcriptional response, identify key mediators, signaling pathways, and transcriptional modules that are affected by HTS in the presence of a strong inflammatory stimulus. Our results suggest that primary human small airway lung epithelial cells (SAECS) exposed to HTS in the presence and absence of a strong pro-inflammatory stimulus exhibit very distinct effects on cellular response, where HTS is highly effective in attenuating cytokine-induced pro-inflammatory responses via mechanisms that involve transcriptional regulation of inflammation which is cell type and stimulus specific. HTS is a highly effective anti-inflammatory agent that inhibits the chemotaxis of leucocytes towards a pro-inflammatory gradient and may attenuate the progression of both the innate and adaptive immune response.</p>
                    </div>
                </div>
            </div>

            <div className="thirteen">
                <div className="left">
                    <h3 className="thirteen-title"  onClick={() => {
                        window.open("https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9788022/")
                    }}>Current and Emerging Treatment Options in Sinus and Nasal Diseases: A Promising Future in the Appropriate Therapies</h3>
                    <div className="link-top"></div>
                    <div className="content">
                        <h5>Lin Lin and Lei Cheng</h5>
                    </div>
                </div>
                <div className="right">
                    <div className="text">
                        <h4>Abstract</h4>
                        <p>Chronic rhinosinusitis (CRS) is an inflammatory condition of the nose and paranasal sinuses defined by clinical symptoms, including two or more symptoms, one of which should be either nasal blockage or nasal discharge with or without facial pain/pressure or reduction in/loss of sense of smell. Most specialists require both symptoms and auxiliary examinations such as endoscopy and computed tomography to characterize the chronicity as lasting more than 12 weeks. The prevalence of CRS is estimated to be 10-28% on the basis of self-reported investigations and is approximately 4-9% after the above examinations.</p>
                    </div>
                </div>
            </div>

            <div className="fourteen">
                <div className="left">
                    <h3 className="fourteen-title"  onClick={() => {
                        window.open("https://pubmed.ncbi.nlm.nih.gov/29253007/")
                    }}>Attenuation of exercise induced asthma by local hyperthermia</h3>
                    <div className="link-top"></div>
                    <div className="content">
                        <h5>S L Johnston, D Perry, S O'Toole, Q A Summers, S T Holgate</h5>
                    </div>
                </div>
                <div className="right">
                    <div className="text">
                        <h4>Abstract</h4>
                        <p><span className="point">Background: </span>Prior treatment with local hyperthermia has been shown to prevent mast cell degranulation and leucocyte histamine release, and to reduce mortality and cellular infiltrates in a model of acute lung injury. Local hyperthermia is effective in reducing the symptoms of the common cold and perennial and seasonal allergic rhinitis, nasal patency also being improved in rhinitis. It is possible that these effects are mediated by common anti-inflammatory mechanisms, and that this treatment may be effective in the treatment of asthma. The effect of prior local hyperthermia on the response to exercise challenge and histamine bronchoprovocation was therefore examined.</p>
                        <p><span className="point">Methods: </span>In a randomised, double blind, placebo controlled, crossover study, 10 asthmatic subjects with exercise induced asthma used machines delivering 40 1/minute of fully humidified air at either 42 degrees C (active treatment) or 31 degrees C (placebo treatment) for 30 minutes' tidal breathing. For each pretreatment, at two week intervals they underwent exercise challenges starting one and 24 hours after starting the inhalations. After a further two weeks the protocol was repeated with histamine substituted for the exercise challenges.</p>
                        <p><span className="point">Results: </span>The mean (SE) maximum percentage fall in forced expiratory volume in one second (FEV1) was significantly lower one hour after treatment with air at 42 degrees C (30.8% (3.1%)) than after treatment with air at 31 degrees C (22.3% (2.9%)). There was no significant effect on exercise challenge at 24 hours, or on histamine challenge at either time point, though there were nonsignificant trends towards protection with exercise at 24 hours and with histamine at one hour.</p>
                        <p><span className="point">Conclusion: </span>In asthmatic subjects the response to exercise challenge is significantly attenuated one hour after treatment with local hyperthermia. This treatment warrants further investigation in the treatment of clinical asthma and other inflammatory disorders.</p>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ClinicalStudies