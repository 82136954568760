import './index.scss'

function About() {


    return(
        <div className="about">
            <div className="first"></div>
            <div className="two">
                <h3 className="title">RHINOTHERM </h3>
                <p>Established in 1985, developed and patented medical device to treat patients suffering from nasal congestion. The device works by delivering pressurized air with the vaporized nasal washing liquid or Hypertonic saline at a temperature of about 43 ℃ (110F) directly to the nasal passages. This quick and easy treatment offers patients fast relief and which can last up to several weeks and improving rhinitis symptoms and nasal airflow. The effective rate for allergic rhinitis is 89-91%.</p>
                <p>Rhinotherm Ultra Devices, have been subjected to clinical studies in 10 countries and found to be an effective Drug-free source of relief for symptoms caused by nasal congestion associated with the allergic rhinitis and sinusitis.</p>
            </div>
            <div className="three">
                <h3>CERTIFICATES AND APPROVED</h3>
                <p className="img"></p>
                <p>FDA, CE, TUV. ISO 9001 13485... </p>
            </div>
        </div>
    )
}

export default About