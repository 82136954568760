
import './product.scss'

function Product() {

    return(
        <div className="product">
            <div className="first">
                <div className="img"></div>
                <div className="center">
                    <h2>ALLERGIC RHINITIS </h2>
                </div>
                <div className="right">
                    <p>Allergic Rhinitis</p>
                    <p>Sketch Map</p>
                    <span className="img"></span>
                </div>
            </div>

            <div className="two">
                <ul>
                    <li>—  Allergic reaction to variety of chemicals (Dust mite, Molds, Plants, Fur….)</li>
                    <li>—  Perennial and seasonal forms</li>
                    <li>—  Local inflammatory reaction – variety of cytokins</li>
                    <li>—  Medications – local and systemic steroids, antihistamines</li>
                    <li>—  Treatment side effects – drowsiness, epistaxis, nasal mucosal dryness, systemic steroids side
                        effects (high BP, osteoporosis, cardiac disease, diabetes…)</li>
                </ul>
            </div>

            <div className="three">
                <h3 className='title'>HYPERTHERMIA METHOD </h3>
                <ul>
                    <li>Heated, humidified air has been used since the invention of the steam kettle.</li>
                    <li>Humidified air at 43°C (110°F) is an effective treatment for Allergic rhinitis   that does not damage the nasal mucosa.</li>
                </ul>
            </div>

            <div className="four">
                <h3 className='title'>DEVICE DEVELOPMENT</h3>
                <p>RHINOTHERM based on the work of:</p>
                <ul>
                    <li>Professor Andre Lwoff, 1965 Nobel Prize winner (Pasteur Institute of
                        Paris) - studied the effects of temperature on virus development and infection.</li>
                    <li>Dr. A. Yerushalmi from the Weizmann Institute of Science.</li>
                    <li>Research team in Israel discovered that hyperthermia was effective in nasal congestion.
                        RHINOTHERM development.</li>
                    <li>Improved technology - reduced cost RHINOTHERM  for home use.</li>
                </ul>
            </div>

            <div className="five">
                <h3 className='title'>RHINOTHERM PRODUCT PRINCIPLES </h3>
                <ul>
                    <li>Saturated 100% RH pressurized air at 43°C (110°F) directly to the nose at 28 L/Min.</li>
                    <li>Constant hot airflow overcomes the Air- Conditioning effect of the nose</li>
                    <li>43°C- Incompatible with“life temperature”</li>
                    <li>Duration of  treatment 15 to 30 minutes</li>
                    <li>Symptom relief for several days up to one month.</li>
                    <li>RHINOTHERM = Drug Free Treatment.</li>
                    <li>Low cost – home, office, schools, hospitals</li>
                </ul>
            </div>

            <div className='six'>
                <h3 className='title'>RHINOTHERM ULTRA II</h3>
                <dl>
                    <dt></dt>
                    <dd>
                        <p>Most people who suffer from allergy symptoms find relief with antihistamines and traditional cold relief medicine. These can often cause unwanted and uncomfortable side effects to the point where many people would rather suffer with the symptoms than use the remedy.
                            But now there is a new source of relief, which uses no drugs, just water or saline solution, thus preventing adverse side effects caused by drugs. Based on the principle of hyperthermia, water- saturated pressurized air is delivered directly to the nasal passages.</p>
                        <p>This new relief is the "Rhinotherm Ultra 2." Developed by a team of scientists and engineers, the Rhinotherm has proven to be effective for relieving the nasal congestion in numerous clinical studies performed worldwide. Relief after a 15 to 30 minutes treatment can last up to several days. The temperature of the water and the duration of treatment are monitored by a small digital unit on the appliance.</p>
                        <p>The Rhinotherm appliance was subjected to over ten clinical trials (some of them on a randomized double-blind basis), conducted in eight countries, ranging from Japan and China in the East to Europe and Canada in the West, and was successful in over 75% of the cases in increasing nasal patency and decreasing nasal discharge. An asthmatic attack occurred in less than 1% of asthmatic patients during treatment.</p>
                        <p>The "Rhinothemi Ultra 2" can be used by both adults and children, although pregnant women should consult their physicianf.</p>
                    </dd>
                </dl>
            </div>

            <div className='seven'>
                <h3 className='title'>RHINOTHERM ULTRA III</h3>
                <dl>
                    <dt></dt>
                    <dd>
                        <p>Is a novel medical device designed for the consumer market and can be used in every home, school, office and geriatric care for the treatment of Nasal congestion, Allergies, Sinusitis and more.</p>
                        <p>The unique device is a Natural alternative for using drugs to treat nasal congestion symptoms. The Rhinotherm Ultra 3 device is a new generation of a previous model which had been sold to clinicians around the world.</p>
                        <p>Several years of development resulted in an improved technology and re-designed device suitable to the private consumer.</p>
                        <p>The device is based on hyperthermia technology, operates on highly important principle of delivering 100% water- saturated pressurized air at a temperature around 43°C (110°F) directly to the nasal passages via a hand-held tube.</p>
                        <p>Duration of a treatment is only 20 to 25 minutes and on average offers the patient a fast relief that can last up to several days even several weeks.</p>
                        <p>When suffering from a Cold or during allergy season, between two or three treatments per week are recommended for maximum relief.</p>
                    </dd>
                </dl>
            </div>

            <div className='eight'>
                <h4>HIGHLIGHTS</h4>
                <ul>
                    <li>—   Drug free treatment</li>
                    <li>—   Long lasting relief from symptoms of common</li>
                    <li>—   allergic rhinitis</li>
                    <li>—   Free of side effects</li>
                    <li>—   A Family device</li>
                </ul>
            </div>

            <div className='nine'>
                <h3 className='title'>RHINOTHERM ULTRA IV</h3>
                <dl>
                    <dt></dt>
                    <dd>
                        <p>To solve the respiratory problems caused by pollen, the attached pollen on the surface and deep part of the nasal cavity can be effectively removed, and allergens can be cut off from the root. In response to the weakened mucosal function caused by runny nose, it can increase the hydration of the mucosal mucus layer, increase the frequency of cilia oscillation, promote mucosal self-repairment, accelerate blood circulation, and alleviate symptoms of nasal congestion, itching, sneezing, and dry throat pain, etc.</p>
                        <p>It can be used by adults, the elderly, or children, and can be used in the home, school, office, and elderly care centre to alleviate symptoms of nasopharyngeal discomfort.</p>
                        <p>Duration of a treatment is 10 minutes each time, 1-2 times a day, with a course of treatment of 2 weeks.</p>
                    </dd>
                </dl>
            </div>

            <div className='ten'>
                <h4>HIGHLIGHTS</h4>
                <ul>
                    <li>—   Safe with no side effects</li>
                    <li>—   The symptom would be relieved in 5 minutes after the treatment</li>
                    <li>—   Allergic rhinitis</li>
                    <li>—   A family device</li>
                </ul>
            </div>

            <div className='eleven'>
                <dl>
                    <dt></dt>
                    <dd>
                        <p>Suitable for allergic rhinitis or vasomotor rhinitis caused by pollen or dust mites, etc. It can effectively remove allergens attached to the surface and depths of the nasal cavity, and through a combination of multiple methods, alleviate inflammatory symptoms, reduce mucosal edema, inhibit inflammatory factors such as histamine and leukotriene, and alleviate discomfort symptoms such as nasal congestion, itching, sneezing, and runny nose.</p>
                        <p>Suitable for adults, children and the elderly people must be used under the guardianship of adults, it can be used to alleviate nasal discomfort symptoms such as stuffiness and itching.</p>
                        <p>Duration of a treatment is 10 minutes each time, 1-2 times a day, with a course of treatment of 2 weeks.</p>
                    </dd>
                </dl>
            </div>

            <div className='twelve'>
                <h4>HIGHLIGHTS</h4>
                <ul>
                    <li>—   Safe with no side effects</li>
                    <li>—   Efficient</li>
                    <li>—   Long lasting protection</li>
                    <li>—   Allergic rhinitis</li>
                    <li>—   A family device</li>
                </ul>
            </div>
            <div className='thirteen'>
                <div className='certificate'>
                    <h3 className="c-title" >CERTIFICATES AND APPROVED</h3>
                    <div className='content'>
                        <span className="img1"></span>
                        <span className="img2"></span>
                        <span className="img3"></span>
                        <span className="img4"></span>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Product